<template>
  <BaseModal
    :title="_T('@Newsletter subscription')"
    @on-close-modal="onCloseModal"
  >
    <form class="modal-installer">
      <p class="modal-installer__title">
        {{ _T("@Subscription form title") }}
      </p>
      <InputText
        v-model="form.name"
        :validate="v.name"
        :maxlength="255"
        :caption="_T('@Name')"
        autocomplete-type="given-name"
      />
      <InputEmail v-model="form.email" :validate="v.email" caption="E-mail" />
      <BaseCheckbox
        v-model="form.isAgreementAccepted"
        :validate="v.isAgreementAccepted"
      >
        <span>
          {{ _T("@I agree with") }}
          <MyLink name="privacy-policy" target="_blank">
            {{ _T("@privacy policy") }}
          </MyLink>
        </span>
      </BaseCheckbox>
      <ButtonPrimary
        type="submit"
        :aria-label="_T('@Send')"
        button-width="363px"
        :disabled="sendForm.isHandled"
        @click.prevent="onSubmit"
      >
        {{ _T("@Send") }}
      </ButtonPrimary>
    </form>
  </BaseModal>
</template>

<script setup>
import useVuelidate from "@vuelidate/core";
import InputText from "~/modules/shared/inputs/InputText.vue";
import InputEmail from "~/modules/shared/inputs/InputEmail.vue";
import {
  successfulModal,
  useModalStore,
  installerSubscriptionModal,
} from "~/modules/shared/modals/store/modalStore";
import {
  emailValidate,
  maxLengthValidate,
  requiredValidate,
  sameAsValidate,
} from "~/utils/validators";
import { modalFormStore } from "~/modules/shared/modals/store/modalFormStore";
import { useUserStore } from "~/store/userStore";
import { useSingletonHandler } from "~/uses/useSingletonHandler";
import { useAPI } from "~/uses/useMyFetch";
import BaseModal from "~/modules/shared/modals/BaseModal.vue";
import ButtonPrimary from "~/modules/shared/buttons/ButtonPrimary.vue";
import BaseCheckbox from "~/modules/shared/inputs/BaseCheckbox.vue";

const modalStore = useModalStore();
const formStore = modalFormStore();
const userStore = useUserStore();

const { currentUser: user } = storeToRefs(userStore);

onMounted(() => {
  if (null !== user.value) {
    form.firstName = user.value.name.firstName;
    form.email = user.value.email;
  }

  formStore.addForm({ v, id: "subscription" });
});

const successfulData = {
  title: _T("@Successful modal title"),
  description: _T("@Successful modal text"),
  isWithTimeout: true,
};

const form = reactive({
  name: "",
  email: "",
  isAgreementAccepted: false,
});

const rules = computed(() => ({
  name: {
    required: requiredValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 255),
  },
  email: {
    required: requiredValidate(),
    email: emailValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 254),
  },
  isAgreementAccepted: {
    sameAs: sameAsValidate("@You must accept the terms and conditions", true),
  },
}));

const v = useVuelidate(rules, form);

const sendForm = useSingletonHandler(() =>
  useAPI("/marketing/event", {
    method: "POST",
    body: {
      event: "installerRequestInitiated",
      name: form.name,
      email: form.email,
      isConfirmed: true,
    },
  }).then(() => {
    modalStore.toggleModal(installerSubscriptionModal);

    modalStore.toggleModal(successfulModal, successfulData);
  }),
);

const onSubmit = async () => {
  if (!formStore.isFormsReady()) {
    return;
  }

  return await sendForm.handle();
};

const onCloseModal = () => {
  modalStore.toggleModal(installerSubscriptionModal);
};

onUnmounted(() => {
  formStore.resetForms();
});
</script>

<style lang="scss" scoped>
.modal-installer {
  @include flex-container(column, center, center);
  gap: 24px;

  &__title {
    width: min(100%, 363px);

    @include font;
    text-align: center;
  }
}
</style>
